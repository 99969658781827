
      @import "src/styles/_variables.scss";
      @import "src/styles/_mixins.scss";
    
.login_container {
  width: 100vw;
  height: 100vh;
  background-color: #f8f9fc;

  .login_wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    justify-content: center;

    box-sizing: border-box;
    width: 90%;
    max-width: 400px;
    margin: auto;
    padding: 64px 40px 48px;

    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgb(42 39 65 / 10%);
  }

  .login_header {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }

  .img_logo500 {
    width: 200px;
    height: 37px;
  }

  .google_login_button {
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 14px 30px;

    font-weight: 600;

    border: 1px solid #bec3cc;
    border-radius: 8px;
  }

  .icon_google {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }

  .login_message {
    margin-top: 24px;

    font-size: 12px;
    line-height: 140%;
    color: #5e6066;
    text-align: center;
    word-break: normal;
  }

  .privacy_underline {
    text-decoration: underline;
  }
}
