
      @import "src/styles/_variables.scss";
      @import "src/styles/_mixins.scss";
    
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
}

.logo {
  width: 240px;
  height: auto;
}

.inapp_browser_description {
  margin: 0;
  margin-top: 40px;

  font-size: 18px;
  font-weight: 400;
  line-height: 1.4;
}

.browsers {
  width: 220px;
  margin-top: 30px;
}

.open_button {
  margin-top: 30px;
  padding: 14px 40px;

  font-size: 16px;
  font-weight: 600;
  color: white;

  background: #6758ff;
  border: none;
  border-radius: 8px;

  &:hover {
    background: #5246cc;
  }
}
